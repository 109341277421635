import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide, } from "swiper/react";
import './App.css';
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Pagination, Mousewheel } from "swiper";

function App() {

  const [swiper, setSwiper] = useState();
  const [isActive, setIsActive] = useState(false);
  const [mouseMove, setMove] = useState({left: 0, top: 0});

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  SwiperCore.use([Mousewheel]);
  
  return (
    <div className="App">
      <Swiper
        direction={"vertical"}
        modules={[Pagination]}
        mousewheel={true}
        onSwiper={(swiper) => setSwiper(swiper)}
        className="mySwiper"
      >
        <SwiperSlide>
        <div class="welcome">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 z-100">
                        <img src="/imgs/logo.svg" alt="" />
                        <a href="#about-us" id="get-started" class="down"><i class="fa fa-chevron-down"></i></a>
                    </div>
                </div>
            </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <section class="about-us" data-hash="about-us">
            <header>
                <div class="nav-bar">
                    <a href="#" onClick={(e) => swiper.slideTo(0)} class="logo">
                        <img src="./imgs/logo.svg" alt="" />
                    </a>
                    <div className={isActive ? "navigation active" : "navigation ms-auto"}>
                        <div class="nav-items">
                            <i class="fa fa-times nav-close-btn" onClick={handleClick}></i>
                            <a href="#" onClick={(e) => swiper.slideTo(1)}><i class="uil uil-home"></i> Home</a>
                            <a href="#" onClick={(e) => swiper.slideTo(2)}><i class="uil uil-home"></i> About</a>
                            <a href="#" onClick={(e) => swiper.slideTo(3)}><i class="uil uil-compass"></i> Services</a>
                            <a href="#" onClick={(e) => swiper.slideTo(4)}><i class="uil uil-info-circle"></i> Clients</a>
                            <a href="#" onClick={(e) => swiper.slideTo(5)}><i class="uil uil-document-layout-left"></i> Contact Us</a>
                        </div>
                    </div>
                    <button onClick={handleClick}>
                      <i class="fa fa-bars nav-menu-btn"></i>
                    </button>
                </div>
                <div class="scroll-indicator-container">
                    <div class="scroll-indicator-bar"></div>
                </div>
            </header>
            <div class="container h-100">
            <div class="row d-flex align-items-center">
                <div class="col-xl-6 col-lg-6 col-md-12  animated" data-aos="fade-up">
                        <img src="./imgs/moe.svg" width="100%" alt="" />
                        <p class="description"><span>Times Consulting</span> was established in 2007 as a marketing communication agency. in 15 years we have grown to a leading ooh company in azerbaijan that provides creative and effective advertisement solutions.  </p>
                    <button class="btn-gradient p-3 pe-4 ps-4" onClick={(e) => swiper.slideTo(5)}>I Want To Be Your Client</button>
                </div>
                    <div class="col-xl-6 col-lg-6 col-hide mb-5">
                        <div class="about-image ms-3">
                        <img src="./imgs/face.webp" data-val="4" data-aos-delay="600" class="w-100 img-single" alt="" />
                    </div>
                </div>
                </div>
            </div>
        </section>
        </SwiperSlide>
        <SwiperSlide>
        <div class="our-team" >
        <header>
                <div class="nav-bar">
                    <a href="#" onClick={(e) => swiper.slideTo(0)} class="logo">
                        <img src="./imgs/logo.svg" alt="" />
                    </a>
                    <div className={isActive ? "navigation active" : "navigation ms-auto"}>
                        <div class="nav-items">
                            <i class="fa fa-times nav-close-btn" onClick={handleClick}></i>
                            <a href="#" onClick={(e) => swiper.slideTo(1)}><i class="uil uil-home"></i> Home</a>
                            <a href="#" onClick={(e) => swiper.slideTo(2)}><i class="uil uil-home"></i> About</a>
                            <a href="#" onClick={(e) => swiper.slideTo(3)}><i class="uil uil-compass"></i> Services</a>
                            <a href="#" onClick={(e) => swiper.slideTo(4)}><i class="uil uil-info-circle"></i> Clients</a>
                            <a href="#" onClick={(e) => swiper.slideTo(5)}><i class="uil uil-document-layout-left"></i> Contact Us</a>
                        </div>
                    </div>
                    <button onClick={handleClick}>
                      <i class="fa fa-bars nav-menu-btn"></i>
                    </button>
                </div>
                <div class="scroll-indicator-container">
                    <div class="scroll-indicator-bar"></div>
                </div>
            </header>
          <div class="container h-100">
              <div class="row h-100 d-flex align-items-center">
                  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                      <h5 class="mb-3 text-white">
                          OUR TEAM IS WORKING HARD EVERY DAY TO FIND
                          NEW WAYS TO COMMUNICATE WITH PEOPLE EFFECTIVELY.
                      </h5>
                      <p class="description" >
                          <span class="d-inline-block mb-2">WE ARE TRYING TO CREATE ADVERTISEMENTS THAT <br /> TALK WITH PEOPLE AND IN THE SAME TIME <br /> LOOKS GOOD AS A INTERIOR/EXTERIOR PIECES. <br /></span> <br />
                          AS EVERY PROGRESSIVE COMPANY WE ARE NOT JUST MAKING SAME THINGS THROUGH THE YEARS, BUT CONSIDER SITUATIONS, PECULIARITIES AND EVERYTHING AROUND US TO MAKE EXACTLY WHAT WOULD ACTUALLY WORK AND AT THE SAME TIME LOOKS AMAZING.
                      </p>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 order-1 ms-auto">
                      <div class="team-image">
                          <img src="./imgs/gradient_1.webp" data-val="4" class="img-single" alt="" />
                      </div>
                  </div>
              </div>
          </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="swiper-slide">
            <section class="services">
                <div class="group_2">
                    <img src="./imgs/group_2.png" alt="" />
                </div>
                <header>
                <div class="nav-bar">
                    <a href="#" onClick={(e) => swiper.slideTo(0)} class="logo">
                        <img src="./imgs/logo.svg" alt="" />
                    </a>
                    <div className={isActive ? "navigation active" : "navigation ms-auto"}>
                        <div class="nav-items">
                            <i class="fa fa-times nav-close-btn" onClick={handleClick}></i>
                            <a href="#" onClick={(e) => swiper.slideTo(1)}><i class="uil uil-home"></i> Home</a>
                            <a href="#" onClick={(e) => swiper.slideTo(2)}><i class="uil uil-home"></i> About</a>
                            <a href="#" onClick={(e) => swiper.slideTo(3)}><i class="uil uil-compass"></i> Services</a>
                            <a href="#" onClick={(e) => swiper.slideTo(4)}><i class="uil uil-info-circle"></i> Clients</a>
                            <a href="#" onClick={(e) => swiper.slideTo(5)}><i class="uil uil-document-layout-left"></i> Contact Us</a>
                        </div>
                    </div>
                    <button onClick={handleClick}>
                      <i class="fa fa-bars nav-menu-btn"></i>
                    </button>
                </div>
                <div class="scroll-indicator-container">
                    <div class="scroll-indicator-bar"></div>
                </div>
            </header>
                <div class="container d-flex align-items-center h-100 mt-mb">
                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                            <div class="col-xl-12 col-lg-12 text-center bordered-gradient">
                                <h5 class="text-uppercase">Medıa Placement</h5>
                                <span>Tv and radıo</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center  bordered-gradient">
                                <h5 class="text-uppercase">Indoor Ads</h5>
                                <span>Installatıons, Stands, Monıtors, And Lıghtboxes</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center bordered-gradient">
                                <h5 class="text-uppercase">Dıgıtal Marketıng</h5>
                                <span>Ceo , web desıgn, emaıl marketıng, ıntegratıons</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center  bordered-gradient">
                                <h5 class="text-uppercase">PR</h5>
                                <span>strategıes and pr plans</span>
                            </div>
                           
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                            <div class="col-xl-12 col-lg-12 col-md-12 text-center bordered-gradient">
                                <h5 class="text-uppercase">Outdoor Ads OOH</h5>
                                <span>Monıtors, lıghtboxes all types of ooh ads ın the most popular poınts of the country</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center  bordered-gradient">
                                <h5 class="text-uppercase">Creatıve</h5>
                                <span>KEY VISUALS, TVC, BRANDING, NAMING,Installations, video production</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center bordered-gradient">
                                <h5 class="text-uppercase">TVC</h5>
                                <span>Tv ads</span>
                            </div>
                            <div class="col-xl-12 col-lg-12 text-center  bordered-gradient">
                                <h5 class="text-uppercase">Researches</h5>
                                <span>analytıcs and more</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div> 
        </SwiperSlide>
        <SwiperSlide>
        <section class="clients">
        <header>
                <div class="nav-bar">
                    <a href="#" onClick={(e) => swiper.slideTo(0)} class="logo">
                        <img src="./imgs/logo.svg" alt="" />
                    </a>
                    <div className={isActive ? "navigation active" : "navigation ms-auto"}>
                        <div class="nav-items">
                            <i class="fa fa-times nav-close-btn" onClick={handleClick}></i>
                            <a href="#" onClick={(e) => swiper.slideTo(1)}><i class="uil uil-home"></i> Home</a>
                            <a href="#" onClick={(e) => swiper.slideTo(2)}><i class="uil uil-home"></i> About</a>
                            <a href="#" onClick={(e) => swiper.slideTo(3)}><i class="uil uil-compass"></i> Services</a>
                            <a href="#" onClick={(e) => swiper.slideTo(4)}><i class="uil uil-info-circle"></i> Clients</a>
                            <a href="#" onClick={(e) => swiper.slideTo(5)}><i class="uil uil-document-layout-left"></i> Contact Us</a>
                        </div>
                    </div>
                    <button onClick={handleClick}>
                      <i class="fa fa-bars nav-menu-btn"></i>
                    </button>
                </div>
                <div class="scroll-indicator-container">
                    <div class="scroll-indicator-bar"></div>
                </div>
            </header>
            <div class="container h-100">
                <div class="row d-flex h-100 align-items-center">
                    <div class="col-xl-12">
                        <ul class="logogrid">
                            <li class="logogrid__item">
                                <img src="./imgs/clients/turkish_airlines.svg" class="logogrid__img" alt="Turkish Airlines" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/mercedes.svg" class="logogrid__img" alt="Mercedes" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/audi.svg" class="logogrid__img" alt="Audi" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/nestle.svg" class="logogrid__img" alt="Nestle" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/delonghi.svg" class="logogrid__img" alt="Delonghi" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/skoda.svg" class="logogrid__img" alt="Skoda" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/kontakthome.png" class="logogrid__img" alt="Kontakt Home" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/cola.svg" class="logogrid__img" alt="Coca Cola" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/vtb.svg" class="logogrid__img" alt="VTB" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/samsung.svg" class="logogrid__img" alt="Samsung" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/pepsi.svg" class="logogrid__img" alt="Pepsi" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/mastercard.svg" class="logogrid__img" alt="MasterCard" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/abb.png" class="logogrid__img" alt="ABB" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/Bakcell.png" class="logogrid__img" alt="Bakcell" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/Irshad.png" class="logogrid__img" alt="Irshad" />
                            </li>
                            <li class="logogrid__item">
                            <img src="./imgs/clients/azercell.svg" class="logogrid__img" alt="Azercell" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/freedom.png" class="logogrid__img" alt="Freedom" />
                            </li>
                            <li class="logogrid__item">
                                <img src="./imgs/clients/Unibank.png" class="logogrid__img" alt="Unibank" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        </SwiperSlide>
        <SwiperSlide>
        <div class="swiper-slide">
            <div class="contact-us">
            <header>
                <div class="nav-bar">
                    <a href="#" onClick={(e) => swiper.slideTo(0)} class="logo">
                        <img src="./imgs/logo.svg" alt="" />
                    </a>
                    <div className={isActive ? "navigation active" : "navigation ms-auto"}>
                        <div class="nav-items">
                            <i class="fa fa-times nav-close-btn" onClick={handleClick}></i>
                            <a href="#" onClick={(e) => swiper.slideTo(1)}><i class="uil uil-home"></i> Home</a>
                            <a href="#" onClick={(e) => swiper.slideTo(2)}><i class="uil uil-home"></i> About</a>
                            <a href="#" onClick={(e) => swiper.slideTo(3)}><i class="uil uil-compass"></i> Services</a>
                            <a href="#" onClick={(e) => swiper.slideTo(4)}><i class="uil uil-info-circle"></i> Clients</a>
                            <a href="#" onClick={(e) => swiper.slideTo(5)}><i class="uil uil-document-layout-left"></i> Contact Us</a>
                        </div>
                    </div>
                    <button onClick={handleClick}>
                      <i class="fa fa-bars nav-menu-btn"></i>
                    </button>
                </div>
                <div class="scroll-indicator-container">
                    <div class="scroll-indicator-bar"></div>
                </div>
            </header>
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-7 form-wrapper">
                            <div class="contact-form ">
                                <h1>Contact us!</h1>
                                <form action="" class="row"> 
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">Your Name: </label>
                                            <input type="text" class="form-input" required />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">Your Email:</label>
                                            <input type="text" class="form-input" required />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">Feel Free To Ask:</label>
                                            <textarea class="form-input" size="false" required></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center">
                                        <button class="btn-submit"><span>Send</span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <ul class="d-flex justify-content-center social-media-links">
                            <li>
                                <a href="https://timesconsulting.az/telegram">
                                    <img src="./imgs/social-icons/telegram.svg" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://timesconsulting.az/instagram">
                                    <img src="./imgs/social-icons/instagram.svg" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://timesconsulting.az/linkedin">
                                    <img src="./imgs/social-icons/linkedin.svg" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://timesconsulting.az/facebook">
                                    <img src="./imgs/social-icons/facebook.svg" alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> 
        </SwiperSlide>
        <SwiperSlide>
        <div class="footer w-100">
            <div class="container-fluid w-100">
                <div class="row mt-auto w-100">
                    <div class="col-xl-12 text-center">
                        <iframe class="d-block w-100 ps-4" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=800&amp;height=400&amp;hl=en&amp;q=152%20Heydar%20Aliyev%20Ave%20Baku+(%C3%87inar%20Plaza)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        <label class="footer-credit">Times Consulting LTD 2022</label>
                    </div>
                </div>
            </div>
        </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default App;
